import Article from '../components/Article';
import Header from '../components/Header';
import '../styles/Home.css';
import carol from '../assets/images/carol.jpg'
import guitar from '../assets/images/guitar.jpg'

function Home() {
  return (
  <div className="home">
    <Header/>
    <div className="body">
      <div className="left">

      </div>
      <div className="middle">
        <Article image={carol} title="Mein Sample" description="Ja, digga, ist wohl eine Sache so."/>
        <Article image={guitar} title="Bester Griddy" description="Der Griddy wurde erstmalig von Steve Griddy im Jahre -1000 gemacht."/>
        <Article image={carol} title="Mein Sample" description="Ja, digga, ist wohl eine Sache so."/>
        <Article image={guitar} title="Bester Griddy" description="Der Griddy wurde erstmalig von Steve Griddy im Jahre -1000 gemacht."/>
      </div>
      <div className="right">

      </div>
    </div>
  </div>
  );
}

export default Home;
