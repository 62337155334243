import '../styles/Article.css'
import Button from 'react-bootstrap/Button';
function Article({title,description,image}){
    return(
        <div className="Article">
            <h3 className="articleTitle">{title}</h3>
            <p className="articleDescription">{description}</p>
            <div className="articleImage"style={{backgroundImage:`url(${image})`}}/>
            <div className="articleButtons">
            <Button variant="primary">Show</Button>{' '}
            <Button variant="outline-primary">View Code</Button>{' '}

            </div>
        </div>
    )
}

export default Article;