import '../styles/Header.css'
function Header(){
    return(
        <div>
            <nav className="nav">
                <ul className="nav_ul">
                    <li className="nav_li"><a href="/"className="nav_a">~home</a></li>
                    <li className="nav_li"><a href="/code"className="nav_a">/code</a></li>
                    <li className="nav_li"><a href="/music"className="nav_a">/music</a></li>
                    <li className="nav_li"><a href="/3d"className="nav_a">/3d</a></li>

                </ul>
            </nav>
        </div>
    )
}

export default Header;
